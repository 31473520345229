// /area/queryList
import service from '@/utils/request.js'
import { requestToken } from '@/utils/request.js'


// 获取省市区
export const getArea = (data) => {
    return requestToken(

        '/area/queryList',
        data
    )
}


// 查询电站列表
export const allStationList = (data) => {
    return requestToken(
        '/station/queryList',
        data
    )
}

// 查询电站总数
export const queryCount = (data) => {
    return requestToken(
        '/station/queryCount',
        data
    )
}

// 保存电站
export const saveSingle = (data) => {
    return requestToken(
        '/station/saveSingle',
        data
    )
}

// 添加数采 /collector/saveSingle   废弃接口
export const collectorsaveSingle = (data) => {
    return requestToken(
        '/collector/saveSingle',
        data
    )
}

// 添加监控器 /device/saveSingle
export const devicesaveSingle = (data) => {
    return requestToken(
        '/device/saveSingle',
        data
    )
}

// 查询单个电站 /station/querySingle
export const querySingleStation = (data) => {
    return requestToken(
        '/station/querySingle',
        data
    )
}

// 更新电站 /station/updateSingle
export const updateSingleStation = (data) => {
    return requestToken(
        '/station/updateSingle',
        data
    )
}

// 删除电站 /station/updateSingle
export const deleteSingleStation = (data) => {
    return requestToken(
        '/station/deleteSingle',
        data
    )
}

// 添加机器人接口 /robot/saveSingle
export const addRobot = (data) => {
    return requestToken('/robot/saveSingle', data)
}
