<template>
  <div class="map-box" id="map-container">
    <div class="legend-box">
      <div class="title">锚点颜色定义:</div>
      <div class="station">
        <div class="circle green"></div>
        <div>无报警电站</div>
      </div>
      <div class="station">
        <div class="circle red"></div>
        <div>有报警电站</div>
      </div>
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { ToDigital } from "@/utils/mapUtils";
// import { number } from 'echarts';
// import { allStationList } from "@/api/powerStation.js";
export default {
  data() {
    return {
      map: null,
      AMap: null,
    };
  },
  props: {
    makerData: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    initMap() {
      console.log(this.makerData);
      AMapLoader.load({
        key: "7aaeac218d34a1d903a125e022ea2c6f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("map-container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 4, //初始化地图级别
            center: [113.594964, 34.799953], //初始化地图中心点位置
          });
          AMap.plugin(["AMap.ToolBar", "AMap.MapType"], () => {
            //加载工具条
            let tool = new AMap.ToolBar();
            this.map.addControl(tool);
            let type = new AMap.MapType({
              defaultType: 0, //使用2D地图
            });
            this.map.addControl(type);
          });
          let makers = [];
          let markerDataList = this.makerData.filter((item) => {
            if (item.latitude != "--" && item.longitude != "--") {
              item.lat = ToDigital(...item.latitude.split(","));
              item.lng = ToDigital(...item.longitude.split(","));
            }
            return item.latitude != "--" && item.longitude != "--";
          });
          console.log(markerDataList);
          markerDataList.forEach((item) => {
            // 255,88,94
            let color =
              item.alarmFlag == 1 ? "rgba(255,88,94,1)" : "rgba(12,207,197,1)";
            const circleMarker = new AMap.CircleMarker({
              center: new AMap.LngLat(Number(item.lng), Number(item.lat)),
              radius: 13, //3D视图下，CircleMarker半径不要超过64px
              strokeColor: "white",
              strokeWeight: 2,
              strokeOpacity: 0.5,
              fillColor: color,
              fillOpacity: 0.8,
              zIndex: 10,
              bubble: true,
              cursor: "pointer",
              clickable: true,
            });
            const infowindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -10),
            });
            circleMarker.on("mouseover", (e) => {
              infowindow.setContent(`
                <div class="info-box">
                  <div class='info-name'>${item.stationName}</div>
                  <div class='alarm-box'>
                    <div class='alram-round ${
                      item.alarmFlag == 1 ? "warning" : "normal"
                    }'></div> ${item.alarmFlag == 1 ? "有报警" : "无报警"}</div>
                </div>
              `);
              infowindow.open(this.map, new AMap.LngLat(item.lng, item.lat));
            });
            circleMarker.on("mouseout", () => {
              infowindow.close(this.map, new AMap.LngLat(item.lng, item.lat));
            });
            makers.push(circleMarker);
          });
          this.map.add(makers);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
<style lang="less" scoped>
.map-box {
  width: 100%;
  height: 100%;
  position: relative;
  .legend-box {
    padding: 0 48px 12px 12px;
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 2;
    background: #fff;
    color: rgba(0, 0, 0, 0.45);
    border-radius: 8px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    .title {
      margin-top: 8px;
    }
    .station {
      display: flex;
      margin-top: 8px;
      align-items: center;
      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 4px;
      }
      .green {
        background: #0ccfc5;
      }
      .red {
        background: #ff595f;
      }
    }
  }
}
/deep/.amap-info-close {
  display: none;
}
/deep/.info-name {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
/deep/.alarm-box {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .alram-round {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }
  .noraml {
    background: #41d068;
  }
  .warning {
    background: #ffb450;
  }
}
</style>