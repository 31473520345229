<template>
    <div class="max-box">
        <div class="header-box">
            <div class="header-left">
                <span>电站</span>
                <a-icon
                    type="table"
                    class="ml-16"
                    :class="currentPage == 1 ? 'active-icon' : ''"
                    @click="changePage(1)"
                />
                <a-icon
                    type="environment"
                    class="ml-16"
                    :class="currentPage == 2 ? 'active-icon' : ''"
                    @click="changePage(2)"
                />
            </div>
            <a-button type="primary" class="add-btn" @click="openTopDrawer">添加电站</a-button>
        </div>
        <div class="content-box">
            <div class="tarbar-box">
                <div class="tabs">
                    <div
                        class="tab-one"
                        v-for="tab in tabList"
                        @click="changeTab(tab.value)"
                    >
                        <div>
                            {{ tab.label }}<span>（{{ total }}）</span>
                        </div>
                    </div>
                    <div style="display: flex">
                        <!--搜索查询-->
                        <a-input v-model="searchValue.stationName" placeholder="输入要查询电站名称" size="small" style="margin-right: 10px" />
                        <a-button type="primary" size="small" @click="getStationList">查询</a-button>
                    </div>
                </div>
                <a-icon type="sync" @click="getStationList" class="reload-icon"/>
            </div>
            <div class="table-container" style="position: relative; overflow: hidden">
                <table-box
                    :data="tableData"
                    :total="total"
                    @requestTable="getStationList"
                    @editStation="editStation"
                    @deleteStation="deleteStation"
                    :table-loading="tableLoading"
                    v-if="currentPage == 1"
                ></table-box>
                <markerMap :makerData="tableData" v-else></markerMap>
                <!-- 筛选 -->
                <a-drawer
                    placement="top"
                    :closable="false"
                    :visible="openFlag"
                    :get-container="false"
                    :wrap-style="{ position: 'absolute' }"
                    @close="onClose"
                    class="screen-drawer"
                    :bodyStyle="{
                background: 'fff',
                height: '100%',
                padding: '0',
              }"
                >
                    <div class="screen-box">
                        <div class="screen-content">
                            <div class="alarm-row">
                                <div class="row-title">装机容量(kWp):</div>
                                <div class="row-main">
                                    <div class="min-max">
                                        <a-input v-model="searchValue.minCapacity"></a-input>
                                        <span> ~ </span>
                                        <a-input v-model="searchValue.maxCapacity"></a-input>
                                    </div>
                                    <div class="capacity-box">
                        <span
                            class="text-type"
                            v-for="i in capacityList"
                            @click="setCapacity(i)"
                        >{{ i.label }}</span
                        >
                                    </div>
                                </div>
                            </div>
                            <div class="alarm-row">
                                <div class="row-title">并网状态:</div>
                                <div class="row-main">
                      <span
                          v-for="item in mergeStateList"
                          @click="changeMergeState(item)"
                      >{{ item.label }}</span
                      >
                                </div>
                            </div>
                            <div class="alarm-row">
                                <div class="row-title">电站类型:</div>
                                <div class="row-main">
                      <span
                          class="text-type"
                          v-for="item in stationTypeList"
                          @click="chooseStationType(item)"
                      >{{ item.label }}</span
                      >
                                </div>
                            </div>
                            <div class="alarm-row">
                                <div class="row-title">系统类型:</div>
                                <div class="row-main">
                      <span
                          class="text-type"
                          v-for="item in systemTypeList"
                          @click="changeSystemType(item)"
                      >{{ item.label }}</span
                      >
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <a-button @click="onClose">取消</a-button>
                            <a-button @click="resetSearch">重置</a-button>
                            <a-button type="primary" @click="searchConfirm">确定</a-button>
                        </div>
                    </div>
                </a-drawer>
            </div>
        </div>

        <add-powerstation
            :topDrawer="topDrawer"
            @closeTopDrawer="closeTopDrawer"
            :id="stationId"
        ></add-powerstation>
    </div>
</template>
<script>
import AddPowerstation from "./components/AddPowerstation.vue";
import TableBox from "./components/TableBox.vue";

import {allStationList, queryCount, deleteSingleStation} from "@/api/powerStation.js";
import {getStationData} from "@/api/station/main";
import markerMap from "./components/markerMap.vue";

export default {
    data() {
        return {
            tableLoading: true, // 表格加载loading
            tabList: [
                {
                    label: "总数",
                    value: 0,
                },
            ],
            activeTab: 0,
            openFlag: false,

            tableData: [],

            topDrawer: false,
            total: 0,
            stationId: "",
            currentPage: 1,
            filterVisible: false,
            capacityList: [
                {
                    min: 0,
                    max: 5,
                    label: "0~5kWp",
                },
                {
                    min: 5,
                    max: 9,
                    label: "5~9kWp",
                },
                {
                    min: 9,
                    max: 16,
                    label: "9~16kWp",
                },
                {
                    min: 16,
                    max: 30,
                    label: "16~30kWp",
                },
                {
                    min: 30,
                    max: 100,
                    label: "30~100kWp",
                },
                {
                    min: 100,
                    max: 500,
                    label: "100~500kWp",
                },
                {
                    min: 500,
                    max: 0,
                    label: "500kWp以上",
                },
            ],
            searchValue: {
                stationType: null,
                systemType: null,
                minCapacity: null,
                maxCapacity: null,
                mergeState: null,
            },
            stationTypeList: [
                {id: 1, label: "分布式户用"},
                {id: 2, label: "分布式商用"},
                {id: 3, label: "分布式工业"},
                {id: 4, label: "地面电站"},
            ], //电站类型
            systemTypeList: [
                {
                    id: 1,
                    label: "光伏+电网",
                },
                {
                    id: 2,
                    label: "光伏+电网+用电",
                },
                {
                    id: 3,
                    label: "光伏+电网+用电+储能",
                },
            ], //系统类型
            // filtrateVisible: false,
            mergeStateList: [
                {
                    id: "1",
                    label: "已并网",
                },
                {
                    id: "0",
                    label: "未并网",
                },
            ],
        };
    },
    components: {AddPowerstation, TableBox, markerMap},
    methods: {
        onSearch() {
        },
        openDown() {
            this.openFlag = !this.openFlag;
            if (!this.openFlag) {
                this.resetSearch();
            }
        },
        resetSearch() {
            for (let k in this.searchValue) {
                this.searchValue[k] = null;
            }
        },
        searchConfirm() {
            this.openFlag = false;
            this.getStationList();
        },
        onClose() {
            this.openFlag = false;
            this.resetSearch();
        },
        changeTab(index) {
            if (index == this.activeTab) return;
            this.activeTab = index;
        },
        openTopDrawer() {
            this.topDrawer = true;
            this.id = "";
        },
        closeTopDrawer() {
            this.topDrawer = false;
            this.id = "";
            this.getStationList();
        },
        getStationList(data) {
            console.log(data, '---')
            let requestData = {
                page: 1,
                limit: 20,
                ...this.searchValue,
            };
            if (data && data.page) {
                requestData.page = data.page;
                requestData.limit = data.pageSize;
            }
            this.tableLoading = true
            console.log(requestData)
            allStationList(requestData).then((res) => {
                res.data.map((item) => {
                    for (let k in item) {
                        if (item[k] == null && k != "coverImg") {
                            item[k] = "--";
                        }
                        if (!isNaN(Number(item[k])) && k == "avgActivePower") {
                            item[k] = Number(item[k]).toFixed(2) + "%";
                        }
                    }
                });
                this.tableData = res.data;
                queryCount(requestData).then(result => {
                    this.total = result.data;
                })
                this.tableLoading = false
            });
        },
        editStation(record) {
            console.log(record)
            this.stationId = record.id;
            this.topDrawer = true;
        },
        deleteStation(record) {
            this.$confirm({
                title: `确认删除${record.stationName}?`,
                okText: "确认",
                cancelText: "取消",
                onOk: () => {
                    deleteSingleStation({id: record.id}).then((res) => {
                        this.$message.success("删除成功");
                        this.getStationList();
                    });
                },
                onCancel: () => {
                    console.log("Cancel");
                },
            });
        },

        changePage(index) {
            if (this.currentPage == index) return;
            this.currentPage = index;
        },
        setCapacity(item) {
            this.searchValue.minCapacity = item.min;
            this.searchValue.maxCapacity = item.max ? item.max : null;
        },
        chooseStationType(item) {
            this.searchValue.stationType = item.id;
        },
        changeSystemType(item) {
            this.searchValue.systemType = item.id;
        },
        changeMergeState(item) {
            this.searchValue.mergeState = item.id;
        },
    },
    mounted() {
        this.getStationList();
        if (this.$route.query.nav) {
            this.openTopDrawer();
        }
        console.log(this.$route);
    },
};
</script>
<style lang="less" scoped>
.max-box {
    width: 100%;
    padding-top: 16px;
    height: 100%;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .header-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-right: 20px;
        margin-bottom: 16px;

        .header-left {
            span {
                font-size: 18px;
                color: #252b3a;
            }

            i {
                font-size: 20px;
                transition: all 0.3s;

                &:hover {
                    color: #048fff;
                }
            }

            .active-icon {
                color: #048fff;
            }
        }

        .search-box {
            width: 240px;
            height: 32px;

            /deep/ .ant-input {
                font-size: 12px;
            }
        }

        .add-btn {
            font-size: 12px;
        }
    }

    .content-box {
        // height: 300px;
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        // overflow: hidden;
        .tarbar-box {
            padding-bottom: 12px;
            position: relative;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            .tabs {
                display: flex;
                padding-left: 16px;
                align-items: center;

                .tab-one {
                    padding: 12px 0 8px 0;
                    margin-right: 24px;
                    font-size: 12px;
                    cursor: pointer;
                    color: #515561;
                }

                .open-choose {
                    margin-top: 3px;
                    cursor: pointer;
                    padding-left: 16px;
                    border-left: 1px solid rgba(0, 0, 0, 0.1);

                    &:hover {
                        color: #048fff;
                    }

                    span {
                        margin-right: 1px;
                    }

                    transition: all 1s;
                }

                .active-choose {
                    color: #048fff;

                    .icon-down {
                        transform: rotate(180deg);
                    }
                }

                .active-tab {
                    color: #048fff;
                    border-bottom: 2px solid #048fff;
                }
            }

            .reload-icon {
                font-size: 18px;
                margin-right: 20px;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    color: #048fff;
                    transform: rotate(90deg);
                }
            }
        }

        .table-container {
            width: 100%;
            // height: 600px;
            background: #fff;
            display: flex;
            flex-grow: 1;
            // height: 100%;
        }
    }

    /deep/ .screen-drawer {
        .ant-drawer-content-wrapper {
            // height: 200px !important;
        }

        .screen-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .screen-content {
                padding-top: 12px;
                flex-grow: 0;
                max-height: 300px;
                overflow-y: auto;

                .alarm-row {
                    display: flex;
                    color: #515561;
                    border-bottom: 1px solid #d9d9d9;
                    font-size: 12px;

                    .row-title {
                        padding: 16px;
                        padding-right: 4px;
                        background: #f0f0f1;
                        width: 130px;
                        display: flex;
                        align-items: center;
                    }

                    .row-main {
                        padding: 16px;
                        // display: flex;

                        .min-max {
                            font-size: 12px;
                            color: #515561;
                            display: flex;
                            align-items: center;

                            span {
                                margin: 0 4px;
                            }

                            /deep/ .ant-input {
                                width: 240px;
                                height: 32px;
                            }
                        }

                        .text-type {
                            margin-right: 16px;
                            cursor: pointer;
                            color: #515561;
                            font-size: 12px;

                            &:hover {
                                color: #048fff;
                            }
                        }

                        .capacity-box {
                            display: flex;
                            color: #515561;
                            font-size: 12px;
                            margin-top: 12px;
                        }
                    }
                }
            }

            .btn-box {
                // flex-grow: 1;
                padding: 16px 0 16px 128px;
                display: flex;
                align-items: center;

                .ant-btn {
                    font-size: 12px;
                    height: 32px;
                    margin-right: 16px;
                }
            }
        }
    }
}
</style>
