<template>
  <div class="map-box" id="map-container" ref="map">
    <div class="search-box">
      <a-input placeholder="输入地址搜索电站位置" v-model="searchValue" />
      <a-button
        :disabled="searchValue == ''"
        type="primary"
        @click="searchAdress"
        >搜索</a-button
      >
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      map: null,
      AMap: null,
      searchValue: "",
      searchVm: null,
      geocoder: null,
    };
  },
  props: {
    lngLat: {
      type: Array,
      default: undefined,
    },
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "7aaeac218d34a1d903a125e022ea2c6f", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("map-container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 5, //初始化地图级别
            center: [113.594964, 34.799953], //初始化地图中心点位置
          });
          AMap.plugin(
            [
              "AMap.ToolBar",
              "AMap.MapType",
              "AMap.PlaceSearch",
              "AMap.Geocoder",
            ],
            () => {
              //加载工具条
              let tool = new AMap.ToolBar();
              this.map.addControl(tool);
              let type = new AMap.MapType({
                defaultType: 0, //使用2D地图
              });
              this.map.addControl(type);

              // 查询地点插件
              this.searchVm = new AMap.PlaceSearch({ map: this.map });

              //逆向编码插件
              this.geocoder = new AMap.Geocoder();
            }
          );
          //   给地图绑定点击事件
          this.map.on("click", this.clickEvent);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    clickEvent(e) {
      let lnglat = [e.lnglat.lng, e.lnglat.lat];

      this.geocoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.info === "OK") {
          // result为对应的地理位置详细信息
          console.log(result, "-------");
          this.$emit("getMapInfo", { lnglat, adress: result });
        }
      });
    },
    searchAdress() {
      console.log(this.searchValue);
      this.searchVm.search(this.searchValue, (status, result) => {
        let pois = result.poiList.pois;
        if (pois.length > 0) {
          this.$notification.config({
            placement: "topLeft",
            bottom: "20px",
            duration: 3,
            getContainer: () => this.$refs.map,
          });
          this.$notification.open({
            message: "",
            description: "搜索到多个符合的结果，请在地图中选择一个作为电站位置",
            style: {
              width: "max-content",
              background: "#048FFF",
              color: "#ffffff",
            },
            closeIcon: null,
          });
        }
        console.log(result, status, pois);
      });
    },
  },
  watch: {
    lngLat(newv) {
      // 清除之前的标记点
      this.map?.clearMap();
      if (this.AMap) {
        let marker = new this.AMap.Marker({
          icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          position: newv,
        });
        this.map?.add(marker);
        this.map?.setZoomAndCenter(14, newv);
      }
    },
  },
  mounted() {
    this.initMap();
  },
};
</script>
<style lang="less" scoped>
.map-box {
  width: 100%;
  height: 100%;
  position: relative;
  .search-box {
    position: absolute;
    z-index: 2;
    display: flex;
    left: 32px;
    top: 16px;
    /deep/.ant-input {
      font-size: 12px;
      width: 336px;
    }
    /deep/.ant-btn {
      font-size: 12px;
      margin-left: 16px;
    }
  }
}
</style>