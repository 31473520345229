import { requestToken } from '@/utils/request.js'

// 查询标签列表
export const allLabelList = (data) => {
    return requestToken('/label/queryList', data)
}

// 删除单个标签
export const deleteLabel = (data) => {
    return requestToken('/label/deleteSingle', data)
}

// 查询单个标签  querySingle
export const querySingleLabel = (data) => {
    return requestToken('/label/deleteSingle', data)
}

// 编辑标签 updateSingle
export const updateSingleLabel = (data) => {
    return requestToken('/label/updateSingle', data)
}

// 保存标签
export const saveLabel = (data) => {
    return requestToken('/label/saveSingle', data)
}


// 给电站绑定已有标签 /stationLabel/bindLabel
export const saveStationLabel = (data) => {
    return requestToken('/stationLabel/bindLabel', data)
}

// 删除当前电站绑定标签 /stationLabel/unBind
export const stationLabelUnBind = (data) => {
    return requestToken('/stationLabel/unBind', data)
}