<template>
    <a-drawer
            placement="top"
            :closable="false"
            :visible="visible"
            height="100%"
            wrapClassName="add-power"
            @close="closeTopDrawer"
            :destroyOnClose="true"
            :headerStyle="{
      background: '#f0f1f5',
      padding: '0 0.2rem 0 0.3rem',
    }"
            :bodyStyle="{
      padding: '0.3rem 0 0 0',
    }"
    >
        <template #title>
            <div class="head-box">
                <span>添加电站</span>
                <div class="head-btn">
                    <a-button @click="closeTopDrawer">取消</a-button>
                    <a-button type="primary" class="save-btn" @click="saveStation"
                    >保存
                    </a-button
                    >
                </div>
            </div>
        </template>
        <div class="content-box">
            <a-row>
                <a-col :span="4">
                    <!-- <div class="left-box">
                      <div class="all-msg">
                        <div>基础信息</div>
                        <div>系统信息</div>
                        <div>收益信息</div>
                        <div>业主信息</div>
                      </div>
                    </div> -->
                </a-col>
                <a-col :span="20">
                    <div class="right-box">
                        <a-form-model :model="form" :rules="rules" ref="ruleForm">
                            <!-- 基础信息 -->
                            <div class="one-peace">
                                <div class="title">
                                    <span>基础信息</span>
                                    <div class="collspan" @click="showBox(1)">
                                        <span>{{ flagList.includes(1) ? "展开" : "收起" }}</span>
                                        <a-icon :type="flagList.includes(1) ? 'down' : 'up'"/>
                                    </div>
                                </div>
                                <div
                                        class="onepeace-detail"
                                        :class="flagList.includes(1) ? 'close-box' : ''"
                                >
                                    <a-form-model-item
                                            label="电站名称"
                                            prop="stationName"
                                            required
                                    >
                                        <a-input v-model="form.stationName"></a-input>
                                    </a-form-model-item>
                                    <a-form-model-item label="电站位置" prop="region">
                                        <div class="map-container">
                                            <Map
                                                    @getMapInfo="getMapInfo"
                                                    :lngLat="ToDigitalNum"
                                            ></Map>
                                        </div>
                                    </a-form-model-item>
                                    <a-form-model-item label="区域" required>
                                        <div class="area-select">
                                            <!-- 国 -->
                                            <a-form-model-item prop="countryId" required>
                                                <a-select v-model="form.countryId">
                                                    <a-select-option
                                                            :key="nation.id"
                                                            v-for="nation in nationList"
                                                    >
                                                        {{ nation.areaName }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>

                                            <!-- 省 -->
                                            <a-form-model-item prop="provinceId" required>
                                                <a-select v-model="form.provinceId">
                                                    <a-select-option
                                                            :key="provice.id"
                                                            v-for="provice in proviceList"
                                                    >
                                                        {{ provice.areaName }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>

                                            <!-- 市 -->
                                            <a-form-model-item
                                                    prop="cityId"
                                                    required
                                                    v-if="form.provinceId"
                                            >
                                                <a-select v-model="form.cityId">
                                                    <a-select-option
                                                            :key="city.id"
                                                            v-for="city in cityList"
                                                    >
                                                        {{ city.areaName }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>

                                            <!-- 区 -->
                                            <a-form-model-item
                                                    prop="countyId"
                                                    required
                                                    v-if="form.cityId && countyList.length != 0"
                                            >
                                                <a-select v-model="form.countyId">
                                                    <a-select-option
                                                            :key="county.id"
                                                            v-for="county in countyList"
                                                    >
                                                        {{ county.areaName }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>
                                        </div>
                                    </a-form-model-item>
                                    <a-form-model-item label="地址" prop="address" required>
                                        <a-input v-model="form.address"></a-input>
                                    </a-form-model-item>
                                    <a-form-model-item label="经纬度" required>
                                        <div class="lng-box">
                                            <span>经度</span>
                                            <a-form-model-item prop="lngdegree">
                                                <a-input v-model="form.lngdegree"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">°</span>
                                            <a-form-model-item prop="lngpoints">
                                                <a-input v-model="form.lngpoints"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">′</span>
                                            <a-form-model-item prop="lngseconds">
                                                <a-input v-model="form.lngseconds"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">″</span>

                                            <span>纬度</span>
                                            <a-form-model-item prop="latdegree">
                                                <a-input v-model="form.latdegree"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">°</span>
                                            <a-form-model-item prop="latpoints">
                                                <a-input v-model="form.latpoints"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">′</span>
                                            <a-form-model-item prop="latseconds">
                                                <a-input v-model="form.latseconds"></a-input>
                                            </a-form-model-item>
                                            <span class="unit">″</span>
                                        </div>
                                    </a-form-model-item>
                                    <div class="time-setting">
                                        <a-form-model-item
                                                label="建站时间"
                                                prop="region"
                                                class="create-box"
                                        >
                                              <span>{{
                                                moment(form.createTime).format("YYYY-MM-DD")
                                              }}</span>
                                        </a-form-model-item>
                                    </div>
                                </div>
                            </div>

                            <!-- 系统信息 -->
                            <div class="one-peace">
                                <div class="title">
                                    <span>系统信息</span>
                                    <div class="collspan" @click="showBox(2)">
                                        <span>{{ flagList.includes(2) ? "展开" : "收起" }}</span>
                                        <a-icon :type="flagList.includes(2) ? 'down' : 'up'"/>
                                    </div>
                                </div>
                                <div
                                        class="onepeace-detail"
                                        :class="flagList.includes(2) ? 'close-box' : ''"
                                >
                                    <div class="type-box">
                                        <div class="one-type">
                                            <div class="type-label">
                                                电站类型：
                                                <a-icon type="question-circle"/>
                                            </div>
                                            <a-select class="type-select" v-model="form.stationType">
                                                <a-select-option
                                                        :key="stationType.id"
                                                        v-for="stationType in stationTypeList"
                                                >
                                                    {{ stationType.label }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                        <div class="one-type">
                                            <div class="type-label">
                                                系统类型：
                                                <a-icon type="question-circle"/>
                                            </div>
                                            <a-select class="type-select" v-model="form.systemType">
                                                <a-select-option
                                                        :key="systemType.id"
                                                        v-for="systemType in systemTypeList"
                                                >
                                                    {{ systemType.label }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item
                                                    label="装机容量(kWp)"
                                                    prop="capacity"
                                                    required
                                            >
                                                <a-input
                                                        placeholder="0.01~100000000"
                                                        v-model="form.capacity"
                                                ></a-input>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type">
                                            <div class="type-label">
                                                井网日期(°)：
                                                <a-icon type="question-circle"/>
                                            </div>
                                            <a-date-picker
                                                    v-model="form.mergeTime"
                                                    :allowClear="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 收益信息 -->
                            <div class="one-peace">
                                <div class="title">
                                    <span>收益信息</span>
                                    <div class="collspan" @click="showBox(3)">
                                        <span>{{ flagList.includes(3) ? "展开" : "收起" }}</span>
                                        <a-icon :type="flagList.includes(3) ? 'down' : 'up'"/>
                                    </div>
                                </div>
                                <div
                                        class="onepeace-detail"
                                        :class="flagList.includes(3) ? 'close-box' : ''"
                                >
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item
                                                    label="货币单位"
                                                    prop="currencyUnit"
                                                    required
                                            >
                                                <a-select class="w400" v-model="form.currencyUnit">
                                                    <a-select-option
                                                            :key="currencyUnit.id"
                                                            v-for="currencyUnit in currencyUnitList"
                                                    >
                                                        {{ currencyUnit.label }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type">
                                            <a-form-model-item
                                                    label="度电收益(元/kWh)"
                                                    prop="unitPrice"
                                            >
                                                <a-input
                                                        placeholder="0.01~100000000"
                                                        v-model="form.unitPrice"
                                                ></a-input>
                                            </a-form-model-item>
                                        </div>
                                    </div>
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item
                                                    label="补贴收益(元/kWh)"
                                                    prop="subsidy"
                                            >
                                                <a-input
                                                        placeholder="0.01~100000000"
                                                        v-model="form.subsidy"
                                                ></a-input>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type">
                                            <a-form-model-item label="总成本(元)" prop="cost">
                                                <a-input
                                                        placeholder="0.01~100000000"
                                                        v-model="form.cost"
                                                ></a-input>
                                            </a-form-model-item>
                                        </div>
                                    </div>
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item label="日还款(元)" prop="dayRepayment">
                                                <a-input
                                                        placeholder="0.01~100000000"
                                                        v-model="form.dayRepayment"
                                                ></a-input>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type"></div>
                                    </div>
                                </div>
                            </div>

                            <!-- 业主信息 -->
                            <div class="one-peace">
                                <div class="title">
                                    <span>业主信息</span>
                                    <div class="collspan" @click="showBox(4)">
                                        <span>{{ flagList.includes(4) ? "展开" : "收起" }}</span>
                                        <a-icon :type="flagList.includes(4) ? 'down' : 'up'"/>
                                    </div>
                                </div>
                                <div
                                        class="onepeace-detail"
                                        :class="flagList.includes(4) ? 'close-box' : ''"
                                >
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item label="业主姓名" prop="merchantName">
                                                <a-input v-model="form.merchantName"></a-input>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type">
                                            <a-form-model-item
                                                    label="业主联系电话"
                                                    prop="telPhone"
                                                    required
                                            >
                                                <a-input v-model="form.telPhone"></a-input>
                                            </a-form-model-item>
                                        </div>
                                    </div>
                                    <div class="type-box">
                                        <div class="one-type">
                                            <a-form-model-item label="业主工作单位" prop="company">
                                                <a-input v-model="form.company"></a-input>
                                            </a-form-model-item>
                                        </div>
                                        <div class="one-type"></div>
                                    </div>
                                </div>
                            </div>
                        </a-form-model>
                    </div>
                </a-col>
            </a-row>
        </div>
    </a-drawer>
</template>
<script>
    import Map from "./Map.vue";
    import moment from "moment";
    import {ToDegrees, ToDigital} from "@/utils/mapUtils.js";
    import {
        getArea,
        saveSingle,
        querySingleStation,
        updateSingleStation,
    } from "@/api/powerStation.js";
    import {validatorTel} from "@/utils/validate.js";

    export default {
        data() {
            const validatorAzimuth = (rule, value, callback) => {
                if (value >= 0 && value <= 360) {
                    callback();
                } else {
                    return callback(new Error("请填写正确的方位角"));
                }
            };
            const validatorDipAngle = (rule, value, callback) => {
                if (value >= 0 && value <= 90) {
                    callback();
                } else {
                    return callback(new Error("请填写正确的倾角"));
                }
            };
            const validatorCapacity = (rule, value, callback) => {
                if (value >= 0.01 && value <= 100000000) {
                    callback();
                } else {
                    return callback(new Error("请填写正确的装机容量"));
                }
            };
            return {
                visible: false,
                form: {
                    stationName: "", //电站名称
                    address: "", //地址
                    lngdegree: "",
                    lngpoints: "",
                    lngseconds: "",
                    latdegree: "",
                    latpoints: "",
                    latseconds: "",
                    capacity: "", //装机容量
                    unitPrice: "", //度电收益
                    currencyUnit: "元", //货币单位
                    subsidy: "", //补贴收益
                    cost: "", //总成本
                    dayRepayment: "", //日还款
                    stationType: 1, //电站类型
                    systemType: 1, //系统类型
                    timeZone: "", //发电站时区
                    createTime: moment(), //建站时间
                    countryId: "", //国家id
                    provinceId: "", //省份id
                    cityId: "", //市区id
                    countyId: "", //区id
                    merchantName: "", //业主姓名
                    telPhone: "", //业主手机
                    mergeTime: moment(), //井网日期
                    azimuth: "", //方位角
                    dipAngle: "", //请填写倾角
                    type: 1, //新增电站所需
                    company: "", //公司单位
                    planPower: "", //计划发电量
                },
                nationList: [], //国家列表
                proviceList: [], //省份列表
                cityList: [], //城市列表
                countyList: [], //区列表
                stationTypeList: [
                    {id: 1, label: "分布式户用"},
                    {id: 2, label: "分布式商用"},
                    {id: 3, label: "分布式工业"},
                    {id: 4, label: "地面电站"},
                ], //电站类型
                systemTypeList: [
                    {
                        id: 1,
                        label: "光伏+电网",
                    },
                    {
                        id: 2,
                        label: "光伏+电网+用电",
                    },
                    {
                        id: 3,
                        label: "光伏+电网+用电+储能",
                    },
                ], //系统类型
                currencyUnitList: [{label: "元", id: "元"}], //货币类型
                rules: {
                    stationName: [
                        {
                            required: true,
                            message: "请填写电站名称",
                            trigger: "blur",
                        },
                        {
                            max: 30,
                            message: "电站名称不能超过30字",
                            trigger: "blur",
                        },
                    ],
                    provinceId: [
                        {
                            required: true,
                            message: "请选择省份",
                            trigger: ["blur", "change"],
                        },
                    ],
                    cityId: [
                        {
                            required: true,
                            message: "请选择所在城市",
                            trigger: ["blur", "change"],
                        },
                    ],
                    countyId: [
                        {
                            required: true,
                            message: "请选择所在区县",
                            trigger: ["blur", "change"],
                        },
                    ],
                    address: [
                        {
                            required: true,
                            message: "请填写具体位置",
                            trigger: "blur",
                        },
                        {
                            max: 100,
                            message: "地址名称不能超过100字",
                            trigger: "blur",
                        },
                    ],
                    lngdegree: [
                        {
                            required: true,
                            message: "请填写完整经度",
                            trigger: "blur",
                        },
                    ],
                    lngpoints: [
                        {
                            required: true,
                            message: "请填写完整经度",
                            trigger: "blur",
                        },
                    ],
                    lngseconds: [
                        {
                            required: true,
                            message: "请填写完整经度",
                            trigger: "blur",
                        },
                    ],
                    latdegree: [
                        {
                            required: true,
                            message: "请填写完整纬度",
                            trigger: "blur",
                        },
                    ],
                    latpoints: [
                        {
                            required: true,
                            message: "请填写完整纬度",
                            trigger: "blur",
                        },
                    ],
                    latseconds: [
                        {
                            required: true,
                            message: "请填写完整纬度",
                            trigger: "blur",
                        },
                    ],
                    stationType: [
                        {
                            required: true,
                            message: "请选择发电站类型",
                            trigger: ["blur", "change"],
                        },
                    ],
                    systemType: [
                        {
                            required: true,
                            message: "请选择系统类型",
                            trigger: ["blur", "change"],
                        },
                    ],
                    capacity: [
                        {
                            required: true,
                            message: "请填写装机容量(kWp)",
                            trigger: "blur",
                        },
                        {validator: validatorCapacity, trigger: "blur"},
                    ],
                    telPhone: [
                        {
                            required: true,
                            message: "请填写业主手机号",
                            trigger: "blur",
                        },
                        {validator: validatorTel, trigger: "blur"},
                    ],
                    azimuth: [
                        {
                            required: true,
                            message: "请填写方位角",
                            trigger: "blur",
                        },
                        {validator: validatorAzimuth, trigger: "blur"},
                    ],
                    dipAngle: [
                        {
                            required: true,
                            message: "请填写倾角",
                            trigger: "blur",
                        },
                        {validator: validatorDipAngle, trigger: "blur"},
                    ],
                    company: [
                        {
                            max: 100,
                            message: "工作单位不能超过50字",
                            trigger: "blur",
                        },
                    ],
                    planPower: [
                        {
                            required: true,
                            message: "请填写计划发电量",
                            trigger: "blur",
                        },
                    ],
                },
                lngDegree: [], //处理的经度
                latDegree: [], //处理的纬度
                moment,
                flagList: [],
            };
        },
        props: {
            topDrawer: {type: Boolean, default: false},
            id: {
                type: String,
                default: "",
            },
        },
        components: {Map},
        watch: {
            async topDrawer(newv) {
                this.visible = newv;
                if (newv) {
                    const res = await this.getAreaList(0);
                    this.nationList = res.data;
                    this.form.countryId = res?.data[0].id;
                }
            },
            async "form.countryId"(newv) {
                const res = await this.getAreaList(newv);
                this.proviceList = res.data;
            },
            async "form.provinceId"(newv) {
                // this.form.cityId = "";
                this.cityList = [];
                const res = await this.getAreaList(newv);
                this.cityList = res.data;
            },
            async "form.cityId"(newv) {
                // this.form.countyId = "";
                this.countyList = [];
                const res = await this.getAreaList(newv);
                this.countyList = res.data;
            },
            id(newv) {
                if (newv) {
                    querySingleStation({id: newv}).then((res) => {
                        if (res.data.stationEntity) {
                            for (let k in this.form) {
                                for (let v in res.data.stationEntity) {
                                    if (k == v) {
                                        this.form[k] = res.data.stationEntity[v];
                                    }
                                }
                            }
                            // 待优化
                            let lng = res.data.stationEntity.longitude.split(",");
                            let lat = res.data.stationEntity.latitude.split(",");
                            this.lngDegree = lng;
                            this.latDegree = lat;
                            this.form.lngdegree = lng[0];
                            this.form.lngpoints = lng[1];
                            this.form.lngseconds = lng[2];
                            this.form.latdegree = lat[0];
                            this.form.latpoints = lat[0];
                            this.form.latseconds = lat[0];
                            this.form.createTime = moment(this.form.createTime);
                            this.form.mergeTime = moment(this.form.mergeTime);
                        }
                    });
                }
            },
        },
        methods: {
            closeTopDrawer() {
                this.$refs.ruleForm.resetFields();
                this.resetForm();
                this.$emit("closeTopDrawer");
            },
            onChange() {
            },
            showBox(index) {
                if (this.flagList.includes(index)) {
                    let i = this.flagList.findIndex((item) => {
                        return item == index;
                    });
                    this.flagList.splice(i, 1);
                } else {
                    this.flagList.push(index);
                }
            },
            getMapInfo(info) {
                let lng = info.lnglat[0].toString();
                let lat = info.lnglat[1].toString();
                this.lngDegree = ToDegrees(lng);
                this.latDegree = ToDegrees(lat);
                this.form.lngdegree = this.lngDegree[0];
                this.form.lngpoints = this.lngDegree[1];
                this.form.lngseconds = this.lngDegree[2];

                this.form.latdegree = this.latDegree[0];
                this.form.latpoints = this.latDegree[1];
                this.form.latseconds = this.latDegree[2];
                console.log(this.lngDegree, this.latDegree);
            },
            // 获取国家
            async getAreaList(id) {
                let data = getArea({parentId: id}).catch((err) => {
                    this.$message.error({
                        content: err || "",
                    });
                });
                return data;
            },
            // 保存电站信息
            saveStation() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        const submitData = JSON.parse(JSON.stringify(this.form));
                        submitData.mergeTime = moment(submitData.mergeTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        submitData.createTime = moment(submitData.createTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        submitData.longitude = this.lngDegree.join(",");
                        submitData.latitude = this.latDegree.join(",");
                        if (this.id) {
                            submitData.id = this.id;
                        }
                        let requestApi = this.id ? updateSingleStation : saveSingle;
                        requestApi(submitData)
                            .then((res) => {
                                if (res.code == "000000") {
                                    this.$message.success({
                                        content: `${this.id ? "修改" : "添加"}电站成功`,
                                    });
                                    this.$refs.ruleForm.resetFields();
                                    this.resetForm();
                                    this.$emit("closeTopDrawer");
                                } else {
                                    this.$message.error({
                                        content: res.message,
                                    });
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else {
                        return this.$message.warning({
                            content: "请填写完整信息",
                        });
                    }
                });
            },

            // 重置信息
            resetForm() {
                let form = {
                    stationName: "", //电站名称
                    address: "", //地址
                    lngdegree: "",
                    lngpoints: "",
                    lngseconds: "",
                    latdegree: "",
                    latpoints: "",
                    latseconds: "",
                    unitPrice: "", //度电收益
                    currencyUnit: "元", //货币单位
                    subsidy: "", //补贴收益
                    cost: "", //总成本
                    dayRepayment: "", //日还款
                    stationType: 1, //电站类型
                    systemType: 1, //系统类型
                    timeZone: "", //发电站时区
                    createTime: moment(), //建站时间
                    countryId: "", //国家id
                    provinceId: "", //省份id
                    cityId: "", //市区id
                    countyId: "", //区id
                    merchantName: "", //业主姓名
                    telPhone: "", //业主手机
                    mergeTime: moment(), //井网日期
                    azimuth: "", //方位角
                    dipAngle: "", //请填写倾角
                    type: 1, //新增电站所需
                    company: "", //公司单位
                    planPower: "",
                };
                this.lngDegree = [];
                this.latDegree = [];
                this.form = form;
            },

            // selectProvice() {
            //   this.form.cityId = "";
            // },
            // selectCity() {
            //   this.form.countyId = "";
            // },
        },
        computed: {
            // 将度分秒转换为度
            ToDigitalNum() {
                let lngDegree = [
                    this.form.lngdegree,
                    this.form.lngpoints,
                    this.form.lngseconds,
                ];
                let latDegree = [
                    this.form.latdegree,
                    this.form.latpoints,
                    this.form.latseconds,
                ];
                return [ToDigital(...lngDegree), ToDigital(...latDegree)];
            },
        },

        mounted() {
        },
    };
</script>
<style lang="less" scoped>
    .add-power {
        .head-box {
            width: 100%;
            height: 64px;
            font-size: 20px;
            color: #252b3a;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .head-btn {
                /deep/ .ant-btn {
                    font-size: 12px;
                }

                .save-btn {
                    margin-left: 8px;
                }
            }
        }

        .content-box {
            width: 100%;
            height: 100%;
            // background: #f60;
            // display: flex;
            padding-top: 24px;

            .left-box {
                // flex: 1;
                display: flex;
                justify-content: flex-end;

                .all-msg {
                    // width: 100px;
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    margin-top: 24px;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    div {
                        // width: max-content;
                        padding: 7px 15px;
                        cursor: pointer;

                        &:hover {
                            color: #048fff;
                        }
                    }
                }
            }

            .right-box {
                // flex: 5;
                // width: 70%;
                padding-right: 27%;
                height: calc(100vh - 128px);
                padding-left: 20px;
                padding-bottom: 16px;
                overflow-y: auto;

                /deep/ .ant-form label {
                    font-size: 12px;
                    color: #00000073;
                }

                /deep/ .ant-form-item-label {
                    line-height: 20px;
                }

                /deep/ .ant-form-item {
                    margin-bottom: 12px;
                }

                .one-peace {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);

                    .title {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        color: #252b3a;
                        margin-bottom: 16px;

                        .collspan {
                            color: #048fff;
                            cursor: pointer;
                        }
                    }

                    .onepeace-detail {
                        max-height: 1200px;
                        box-sizing: border-box;
                        transition: height 2s;

                        .type-box {
                            font-size: 12px;
                            color: #00000073;
                            display: flex;
                            margin-bottom: 24px;

                            .ant-input {
                                width: 400px;
                            }

                            .type-select {
                                width: 240px;
                                height: 32px;
                                font-size: 12px;
                            }

                            .one-type {
                                flex: 1;

                                .type-label {
                                    margin-bottom: 10px;
                                    display: flex;
                                    align-items: center;
                                }

                                .w400 {
                                    width: 400px;
                                }
                            }
                        }
                    }

                    .close-box {
                        height: 0;
                        overflow: hidden;
                        transition: height 2s;
                    }

                    .map-container {
                        height: 360px;
                        width: 100%;
                    }

                    .area-select {
                        display: flex;
                        // align-items: center;
                        /deep/ .ant-select {
                            width: 160px;
                            height: 32px;
                            margin-right: 10px;
                        }
                    }

                    .lng-box {
                        display: flex;

                        span {
                            margin-right: 8px;
                            font-size: 12px;
                        }

                        .unit {
                            margin-right: 12px;
                        }

                        /deep/ .ant-input {
                            width: 72px;
                            height: 32px;
                            margin-right: 8px;
                        }
                    }

                    .time-setting {
                        display: flex;

                        .zone-box {
                            flex: 1;
                        }

                        .select-box {
                            width: 400px;
                            height: 32px;
                        }

                        .create-box {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }

    /deep/ .ant-input {
        font-size: 14px;
    }
</style>
