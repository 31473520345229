<template>
    <div
        class="table-box-power"
        :style="{
        overflow: 'hidden',
        position: 'relative',}"
        ref="yHeight">
        <a-table
                :columns="columns"
                :pagination="false"
                :dataSource="data"
                rowKey="id"
                size="middle"
                :scroll="{ x: 3000, y: yHeight }"
                :loading="tableLoading"
        >
            <template #station="text, record">
                <div class="station-box" @click="rowClick(record)">
                    <img
                            :src="record.coverImg ? record.coverImg : defaultImg"
                            alt=""
                            class="station-img"
                    />
                    <div class="right-text">
                        <span style="cursor: pointer" :title="text">{{ text }}</span>
                        <div class="adress-text" :title="record.address">
                            <a-icon type="environment" class="adress-icon"/>
                            {{record.address }}
                        </div>
                    </div>
                </div>
            </template>
            <!--<template #communication>
                <div class="table-title-box">
                    <span class="table-title">通讯</span>
                    <a-icon
                            type="question-circle"
                            class="question-icon"
                            @click.stop="openMsg('通讯', communicationMsg)"
                    />
                </div>
            </template>-->
            <template #communicateSlot="text">
                <span v-if="getCommunicate(text).value == 0">未知状态</span>
                <img
                        class="img-status"
                        :src="getCommunicate(text).icon"
                        :title="getCommunicate(text).label"
                />
            </template>
            <template slot="normalization">
                <div class="table-title-box">
                    <span class="table-title">功率归一化</span>
                    <a-icon
                            type="question-circle"
                            class="question-icon"
                            @click.stop="openMsg('功率归一化', powerMsg)"
                    />
                </div>
            </template>
            <template slot="fullhours">
                <div class="table-title-box">
                    <span class="table-title">当日满发小时</span>
                    <a-icon
                            type="question-circle"
                            class="question-icon"
                            @click.stop="openMsg('当日满发小时', fullHourMsg)"
                    />
                </div>
            </template>
            <template slot="pr">
                <div class="table-title-box">
                    <span class="table-title">昨日PR</span>
                    <a-icon
                            type="question-circle"
                            class="question-icon"
                            @click.stop="handleSort"
                    />
                </div>
            </template>
            <template #label="text, record">
                <div class="label-style">
                    <div class="one-label" v-for="item in text">{{ item.labelName }}</div>
                    <span @click.stop="editLabel(record)">编辑</span>
                </div>
            </template>
            <template #alarm="text, record">
                <img
                        src="../../../assets/statusicon/normal.png"
                        alt=""
                        v-if="text == 0"
                        class="img-status"
                        title="无报警"
                />
                <img
                        src="../../../assets/statusicon/leida.png"
                        alt=""
                        v-else
                        title="有报警"
                        class="img-status"
                />
            </template>
            <template #sType="text, record">
                {{
                record.stationType == 1
                ? "分布式户用"
                : record.stationType == 2
                ? "分布式商用"
                : record.stationType == 3
                ? "分布式工业"
                : "地面电站"
                }}
            </template>

            <template #sysType="text, record"> {{ dealSystemText(text) }}</template>
            <template #merge="text"> {{ text == 0 ? "未并网" : "已并网" }}</template>
            <template #operation>
                <div class="table-title-box space-between">
                    <span class="table-title">操作</span>
                </div>
            </template>
            <template slot="Controls" slot-scope="text, record">
                <div class="operation-box">
                    <a-icon
                            type="edit"
                            title="编辑"
                            class="icon-style"
                            @click="editStation(record)"
                    />
                    <a-icon
                            type="delete"
                            title="删除"
                            class="icon-style"
                            @click="deleteStation(record)"
                    />
                </div>
            </template>
        </a-table>

        <!-- 表格的操作 -->
        <div class="table-footer">
            <a-modal
                    :title="Title"
                    :visible="visible"
                    @ok="handleOk"
                    @cancel="handleCancel"
            >
                <a-form-model ref="modalForm" :model="modalForm" :rules="modalrules">
                    <a-form-model-item label="sn编码" prop="collectorSn">
                        <a-input v-model="modalForm.collectorSn"></a-input>
                    </a-form-model-item>
                </a-form-model>
            </a-modal>
            <a-drawer
                    title="选择显示列"
                    placement="right"
                    :closable="false"
                    :headerStyle="{
          background: '#f0f1f5',
          paddingLeft: '0.25rem',
          height: '0.6rem',
          fontSize: '0.15rem',
          display: 'flex',
          alignItems: 'center',
        }"
                    :visible="drawerVisible"
                    :get-container="false"
                    :wrap-style="{ position: 'absolute' }"
                    @close="closeDrawer"
            >
                <p>Some contents...</p>
            </a-drawer>
            <labelDrawer
                    :labelVisible.sync="labelVisible"
                    :rowData="rowData"
                    @reloadData="reloadData"
            ></labelDrawer>
            <pagination
                    :page.sync="pagination.page"
                    :pageSize.sync="pagination.pageSize"
                    :total.sync="total"
                    @paginationChange="paginationChange"
            ></pagination>
        </div>
        <a-modal v-model="helpMsg" :title="currentTitle">
            <template slot="footer">
                <a-button type="primary" @click="helpMsg = false"> 我知道了</a-button>
            </template>
            <div v-for="(i, index) in helpList" class="one-alert">
                <img
                        :src="alarmStatus[index - 1].icon"
                        alt=""
                        class="alert-img"
                        v-if="helpList.length == 5 && index != 0"
                />
                {{ i }}
            </div>
        </a-modal>
    </div>
</template>
<script>
    import {collectorsaveSingle, devicesaveSingle} from "@/api/powerStation.js";
    import labelDrawer from "@/views/monitoring/components/labelDrawer";
    import {debound} from "@/utils/index.js";

    export default {
        data() {
            return {
                pagination: {
                    page: 1,
                    pageSize: 20,
                },
                defaultImg: require("../../../assets/defaultAvg.png"),
                yHeight:
                    document.documentElement.clientHeight -
                    document.documentElement.clientHeight / 4.9,
                columns: [
                    {
                        title: "序号",
                        dataIndex: "index",
                        width: 50,
                        customRender: (text, record, index) => {
                            return index + 1;
                        },
                        fixed: "left",
                        align: "center",
                    },
                    {
                        title: "电站名称",
                        width: 250,
                        dataIndex: "stationName",
                        key: "stationName",

                        ellipsis: true,
                        scopedSlots: {
                            customRender: "station",
                        },
                    },
                    // {
                    //     width: 100,
                    //     dataIndex: "communicate",
                    //     key: "",
                    //     slots: {
                    //         title: "communication",
                    //     },
                    //     scopedSlots: {
                    //         customRender: "communicateSlot",
                    //     },
                    // },
                    {
                        width: 80,
                        dataIndex: "alarmFlag",
                        key: "",
                        title: "报警",
                        scopedSlots: {
                            customRender: "alarm",
                        },
                    },
                    {
                        title: "装机容量(kWp)",
                        width: 100,
                        dataIndex: "capacity",
                        key: "capacity",
                        sorter: (a, b) => a.capacity - b.capacity,
                    },
                    {
                        title: "发电功率(KW)",
                        width: 100,
                        dataIndex: "gridPower",
                        key: "",
                        sorter: (a, b) => a.gridPower - b.gridPower,
                    },
                    {
                        title: "功率归一化",
                        width: 100,
                        dataIndex: "avgActivePower",
                        key: "",
                        slots: {
                            title: "normalization",
                        },
                        sorter: (a, b) => a.avgActivePower - b.avgActivePower,
                    },
                    {
                        title: "当日发电量（KW·h）",
                        width: 100,
                        dataIndex: "todayEnergy",
                        key: "",
                        sorter: (a, b) => a.todayEnergy - b.todayEnergy,
                    },
                    {
                        width: 120,
                        dataIndex: "fullHour",
                        key: "",
                        slots: {
                            title: "fullhours",
                        },
                        sorter: (a, b) => a.fullHour - b.fullHour,
                    },
                    /*{
                        width: 200,
                        title: "标签",
                        dataIndex: "labelEntities",
                        scopedSlots: {
                            customRender: "label",
                        },
                    },*/
                    {
                        title: "建站日期",
                        width: 90,
                        dataIndex: "createTime",
                        key: "",
                        sorter: (a, b) => a.createTime - b.createTime,
                    },
                    {
                        title: "并网状态",
                        dataIndex: "mergeState",
                        width: 100,
                        scopedSlots: {
                            customRender: "merge",
                        },
                    },
                    {
                        title: "电站类型",
                        dataIndex: "stationType",
                        width: 100,
                        scopedSlots: {
                            customRender: "sType",
                        },
                    },
                    {
                        title: "系统类型",
                        dataIndex: "systemType",
                        width: 100,
                        scopedSlots: {
                            customRender: "sysType",
                        },
                    },
                    {
                        title: "联系电话",
                        width: 100,
                        dataIndex: "telPhone",
                        key: "telPhone",
                    },
                    {
                        title: "并网日期",
                        width: 100,
                        dataIndex: "mergeTime",
                        key: "",
                        sorter: (a, b) => a.mergeTime - b.mergeTime,
                    },
                    {
                        width: 120,
                        dataIndex: "",
                        key: "",
                        fixed: "right",
                        slots: {
                            title: "operation",
                        },
                        scopedSlots: {customRender: "Controls"},
                    },
                ],
                checkColumns: [],
                selectedRowKeys: [],
                visible: false,
                flag: 0,
                modalForm: {
                    collectorSn: "",
                },
                modalrules: {
                    collectorSn: [
                        {required: true, message: "请填写sn编码", trigger: "blur"},
                    ],
                },
                rowData: {},
                drawerVisible: false,
                labelVisible: false,
                systemTypeList: [
                    {
                        id: 1,
                        label: "光伏+电网",
                    },
                    {
                        id: 2,
                        label: "光伏+电网+用电",
                    },
                    {
                        id: 3,
                        label: "光伏+电网+用电+储能",
                    },
                ], //系统类型

                alarmStatus: [
                    {
                        value: "1",
                        label: "正常",
                        icon: require("../../../assets/statusicon/normal.png"),
                    },
                    {
                        value: "4",
                        label: "接入中：电站下没有任何设备或设备从来没有上传过数据的情况",
                        icon: require("../../../assets/statusicon/alarm.png"),
                    },
                    {
                        value: "2",
                        label: "部分设备离线：电站下有部分设备处于离线状态",
                        icon: require("../../../assets/statusicon/little.png"),
                    },
                    {
                        value: "3",
                        label: "全部设备离线：电站下所有设备处于离线状态",
                        icon: require("../../../assets/statusicon/allOver.png"),
                    },
                ],
                other: {
                    label: "未知状态",
                    value: "0",
                },
                helpMsg: false,
                currentTitle: "",
                helpList: [],
                //
                communicationMsg: [
                    "电站的通讯状态，具体包括:",
                    "正常;",
                    "接入中：电站下没有任何设备或设备从来没有上传过数据的情况;",
                    "部分设备离线：电站下有部分设备处于离线状态;",
                    "全部设备离线：电站下所有设备处于离线状态;",
                ],
                // 功率归一化
                powerMsg: [
                    "功率归一化是衡量一座光伏电站发电能力的重要指标之一，用于衡量电站当前发电能力。",
                    "计算公式：功率归一化=当前发电功率/装机容量",
                    "例如功率归一化为80%，表示此电站以额定功率的80%运行。",
                ],

                // 当日满发小时
                fullHourMsg: [
                    "满发小时是衡量一座光伏电站发电能力的重要指标之一，日满发小时则表示日发电能力。",
                    "计算公式：满发小时=发电量/装机容量",
                    "例如日满发小时为4.5h，表示此电站以额定功率工作了4.5个小时",
                ],
            };
        },
        components: {labelDrawer},
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            total: {
                type: Number,
                default: 0,
            },
            tableLoading: {
                type: Boolean,
                default: true
            },
        },
        created() {
           console.log(this.tableLoading)
        },
        methods: {
            rowClick(record) {
                const routeUrl = this.$router.resolve({
                    path: "/station/main",
                    query: {
                        id: record.id,
                    },
                });
                window.open(routeUrl.href, "_blank");
            },

            paginationChange(data) {
                // 分页变化
                console.log(data)
                this.$emit("requestTable", data);
            },
            tableChange(rowkeys, rows) {
                this.selectedRowKeys = rowkeys;
            },
            // 全选、全不选
            allSelect(flag) {
                // 判断是否全选
                if (flag) {
                    for (let i = 0; i < this.data.length; i++) {
                        if (!this.selectedRowKeys.includes(this.data[i].id)) {
                            this.selectedRowKeys.push(this.data[i].id);
                        }
                    }
                } else {
                    let dealKeys = this.data.map((item) => {
                        return item.id;
                    });
                    this.selectedRowKeys = this.selectedRowKeys.filter((item) => {
                        return !dealKeys.includes(item);
                    });
                }
            },
            handleSort(aaa) {
            },
            getCommunicate(text) {
                let parseText = parseInt(text);
                // console.log(text);
                let find = this.alarmStatus.find((item) => {
                    return item.value == parseText;
                });
                if (find) {
                    return find;
                } else {
                    return this.other;
                }
            },
            watchResize() {
                this.yHeight = this.$refs["yHeight"].clientHeight - 100;
                window.addEventListener("resize", this.screenResize);
            },
            screenResize: debound(function () {
                // document.documentElement.clientHeight;
                this.yHeight = this.$refs["yHeight"].clientHeight - 100;
            }, 200),
            // 提交采集、监控
            handleOk() {
                this.$refs.modalForm.validate((valid) => {
                    if (valid) {
                        const requestData = {
                            stationId: this.rowData.id,
                            collectorSn: this.modalForm.collectorSn,
                            deviceType: 2,
                        };
                        if (this.flag == 1) {
                            delete requestData.deviceType;
                        }
                        let requestFn =
                            this.flag == 1 ? collectorsaveSingle : devicesaveSingle;
                        requestFn(requestData)
                            .then((res) => {
                                if (res.code == "000000") {
                                    this.$message.success({
                                        content: this.Title + "成功",
                                    });
                                    this.visible = false;
                                    this.$emit("requestTable", this.pagination);
                                } else {
                                    this.$message.error({
                                        content: res.message,
                                    });
                                }
                            })
                            .catch(() => {
                                this.$message.error({
                                    content: this.Title + "失败",
                                });
                            });
                    } else {
                        return false;
                    }
                });
            },
            // 关闭采集、监控modal，重置表单
            handleCancel() {
                this.$refs.modalForm.resetFields();
                this.visible = false;
            },
            // 打开采集、监控modal
            openModal(index, data) {
                this.flag = index;
                this.visible = true;
                this.rowData = data;
                this.modalForm.collectorSn = "";
            },
            reloadData() {
                this.$emit("requestTable");
            },
            // 打开抽屉
            opendrawer() {
                this.drawerVisible = true;
            },
            closeDrawer() {
                this.drawerVisible = false;
            },

            editLabel(item) {
                this.labelVisible = true;
                this.rowData = item;
                // console.log(item, "----");
            },
            dealSystemText(id) {
                let system = this.systemTypeList.find((item) => {
                    return item.id == id;
                });
                if (system) {
                    return system.label;
                } else {
                    return "--";
                }
            },

            editStation(record) {
                this.$emit("editStation", record);
            },
            deleteStation(record) {
                this.$emit("deleteStation", record);
            },
            openMsg(title, list) {
                this.currentTitle = title;
                this.helpMsg = true;
                this.helpList = list;
            },
        },
        computed: {
            // 计算是否全选
            allSelected() {
                let flag = true;
                for (let i = 0; i < this.data.length; i++) {
                    if (!this.selectedRowKeys.includes(this.data[i].id)) {
                        flag = false;
                        break;
                    }
                }
                return flag;
            },

            // modal标题
            Title() {
                return this.flag == 1 ? "添加采集器" : "添加监控器";
            },
            isDisabled() {
                return this.selectedRowKeys.length == 0;
            },
        },
        mounted() {
            this.watchResize();
            console.log(this.data);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.screenResize);
        },
    };
</script>
<style lang="less" scoped>
    .table-box-power {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // min-height: 500px;
        .operation-box {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;

            /deep/ .ant-rate {
                font-size: 16px;
            }
        }

        /deep/ .ant-drawer-title {
            font-size: 12px;
        }

        .rate {
            /deep/ .ant-rate-star-second {
                font-size: 16px;
            }

            margin-bottom: 2px;
        }

        .station-box {
            display: flex;
            align-items: center;

            .station-img {
                width: 40px;
                height: 40px;
                margin-right: 8px;
                border-radius: 4px;
            }

            .right-text {
                width: 140px;

                .adress-text {
                    white-space: nowrap;
                    overflow: hidden;
                    cursor: pointer;
                    text-overflow: ellipsis;

                    .adress-icon {
                        margin-right: 4px;
                    }
                }
            }
        }

        .label-style {
            display: flex;
            height: 60px;
            flex-wrap: nowrap;
            overflow-x: auto;
            max-width: 500px;
            align-items: center;

            .one-label {
                font-size: 12px;
                padding: 0 8px;
                margin-right: 8px;
                height: max-content;
                border-radius: 16px;
                border: 1px solid #048fff;
                display: flex;
                align-items: center;
                color: #048fff;
            }

            span {
                cursor: pointer;
                position: relative;
                z-index: 1;

                &:hover {
                    color: #048fff;
                }
            }
        }

        .icon-style {
            margin-left: 15px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.3);
            // position: relative;
            // z-index: 999;
            cursor: pointer;

            &:hover {
                color: #048fff;
            }
        }

        /deep/ .ant-table-header-column {
            width: 100%;
        }

        /deep/ .ant-table-wrapper {
            width: 100%;
            // min-height: 500px;
            // height: ;
            // flex: 1;
        }

        .table-title-box {
            display: flex;
            align-items: center;

            .table-title {
                margin-left: 4px;
            }

            .question-icon {
                position: relative;
                z-index: 999;
                cursor: pointer;
                margin: 0 5px;

                &:hover {
                    color: #048fff;
                }
            }

            .setting {
                margin-right: 20px;
                font-size: 16px;
            }
        }

        .space-between {
            justify-content: space-between;
        }

        /deep/ .ant-table-content {
            font-size: 12px;
            color: #515561;
        }

        .table-footer {
            display: flex;
            justify-content: space-between;
            // height: 48px;
            padding: 12px 16px;
            width: 100%;
            // height: 64px;
            .operation-btn {
                /deep/ .ant-btn {
                    font-size: 12px;
                    margin-right: 8px;
                }
            }
        }
    }

    /deep/
    .ant-table-thead
    > tr
    > th
    .ant-table-column-sorter
    .ant-table-column-sorter-inner-full {
        margin-top: -12px;
    }

    /deep/ .ant-table td {
        white-space: nowrap;
    }

    .iconfont {
        cursor: pointer;
        font-size: 26px;
        font-weight: 200;
    }

    .img-status {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    .one-alert {
        display: flex;
        align-items: center;
    }

    .alert-img {
        width: 22px;
        height: 22px;
        margin-right: 6px;
        // cursor: pointer;
    }
</style>
