<template>
  <a-drawer
    placement="right"
    :closable="false"
    width="30%"
    :headerStyle="{
      background: '#fff',
      padding: '0.2rem 0.25rem 0.2rem 0.25rem',
      flexGrow: 0,
    }"
    :bodyStyle="{
      padding: '0.2rem',
      background: 'fff',
      flexGrow: 1,
      overflowY: 'auto',
    }"
    :drawerStyle="{
      background: '#EEEEEF',
      display: 'flex',
      flexDirection: 'column',
    }"
    :visible="labelVisible"
    @close="closeDrawer"
  >
    <template #title>
      <div class="head-box">
        <span>编辑所属标签</span>
        <div class="close-box" @click="closeDrawer">
          <a-icon type="close" class="close-icon" />
        </div>
      </div>
    </template>
    <div class="content-box">
      <div class="top-content">
        <div class="label-title">标签</div>
        <div class="choose-label">
          <div v-for="c in chooseLable">
            <span>{{ c }}</span>
            <a-icon type="close" class="icon" @click="deleteLabel(c)" />
          </div>
          <a-button type="link" icon="plus" class="add-btn" @click="addLabel">
            添加标签
          </a-button>
        </div>
        <div class="add-box" v-if="addFlag">
          <a-input class="add-input" v-model="addContent"></a-input>
          <div class="add-operation">
            <a-button type="link" @click="cancelAdd" class="cancel-btn">
              取消
            </a-button>
            <a-button
              type="link"
              :disabled="addContent == '' || isDisabled"
              @click="addOneLabel"
            >
              确认
            </a-button>
          </div>
        </div>
        <div class="all-label-tit">所有电站标签</div>
        <div class="all-label">
          <div
            v-for="item in labelList"
            @click="chooseOne(item)"
            :class="check(item) ? 'active-label' : ''"
          >
            {{ item.labelName }}
          </div>
        </div>
      </div>
      <!-- <div class="bottom-btn">
        <a-button @click="closeDrawer">取消</a-button>
        <a-button type="primary" class="ml-16">保存</a-button>
      </div> -->
    </div>
  </a-drawer>
</template>
<script>
import {
  allLabelList,
  saveStationLabel,
  saveLabel,
  stationLabelUnBind,
} from "@/api/label.js";
export default {
  data() {
    return {
      addFlag: false,
      addContent: "",
      chooseLable: [],
      labelList: [],
      isDisabled: false,
    };
  },
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDrawer() {
      this.$emit("update:labelVisible", false);
    },
    addLabel() {
      if (this.addFlag) {
        return;
      } else {
        this.addFlag = true;
      }
    },
    cancelAdd() {
      this.addContent = "";
      this.addFlag = false;
    },
    getAllLabel() {
      allLabelList({}).then((res) => {
        this.labelList = res.data;
        console.log(res);
      });
    },
    chooseOne(item) {
      let findOne = this.chooseLable.find((c) => {
        return c == item.labelName;
      });
      if (!findOne) {
        this.chooseLable.push(item.labelName);
        saveStationLabel({
          id: item.id,
          stationId: this.rowData.id,
        }).then((res) => {
          this.$emit("reloadData");
        });
      }
    },
    check(item) {
      let findOne = this.chooseLable.find((c) => {
        return c == item.labelName;
      });
      if (findOne) {
        return true;
      } else {
        return false;
      }
    },
    // 删除标签
    deleteLabel(item) {
      let index = this.chooseLable.findIndex((i) => {
        return i == item;
      });

      if (index != -1) {
        this.chooseLable.splice(index, 1);
      }
      let labelOne = this.rowData.labelEntities.find((label) => {
        return label.labelName == item;
      });
      if (labelOne) {
        stationLabelUnBind({
          stationLabelId: labelOne.stationLabelId,
        }).then((res) => {
          this.$message.success("删除标签成功");
          this.$emit("reloadData");
        });
      }
    },
    addOneLabel() {
      saveLabel({
        stationId: this.rowData.id,
        labelName: this.addContent,
      }).then((res) => {
        this.chooseLable.push(this.addContent);
        this.getAllLabel();
        this.$emit("reloadData");
      });
    },
  },
  watch: {
    labelVisible(newValue) {
      this.chooseLable = [];
      this.addContent = "";
      this.chooseLable = this.rowData.labelEntities.map((item) => {
        return item.labelName;
      });
      if (newValue) {
        this.getAllLabel();
      }
    },
    addContent(newv) {
      let hasLabel = this.labelList.find((item) => {
        return item.labelName == newv;
      });
      if (hasLabel) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      // this.chooseLable.forEach((item) => {});
    },
  },
};
</script>
<style lang="less" scoped>
.head-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 18px;
  }
  .close-box {
    background: #ff7875;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    font-size: 16px;
    color: #fff;
    border-radius: 50%;
    &:hover {
      i {
        transform: rotate(90deg);
      }
    }
    i {
      transition: all 0.3s;
    }
  }
}
.content-box {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top-content {
    padding: 15px;
    .label-title {
      font-size: 12px;
    }
    .choose-label {
      padding: 2px 22px 2px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      margin-top: 8px;
      max-height: 300px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      .add-btn {
        height: 22px;
        font-size: 12px;
        padding: 0 6px;
        margin: 2px 0;
      }
      div {
        color: #515561;
        font-size: 12px;
        padding: 3px 8px;
        margin-bottom: 3px;
        cursor: pointer;
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.04);
        margin-right: 12px;
      }
      .icon {
        margin-left: 12px;
        &:hover {
          color: #ff7875;
        }
      }
    }
    .add-box {
      padding-top: 8px;
      display: flex;
      align-items: center;
      .add-input {
        width: 50%;
        height: 32px;
        font-size: 12px;
      }
      .add-operation {
        margin-left: 4px;
        .cancel-btn {
          color: rgba(0, 0, 0, 0.45);
          &:hover {
            color: #ff7875;
          }
        }
        .ant-btn {
          font-size: 12px;
          padding: 0 6px;
        }
      }
    }
    .all-label-tit {
      margin-top: 16px;
      font-size: 12px;
    }
    .all-label {
      margin-top: 8px;
      max-height: 380px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      div {
        color: #515561;
        font-size: 12px;
        padding: 6px 8px;
        margin-bottom: 3px;
        cursor: pointer;
        border-radius: 2px;
        margin-right: 6px;
      }
      .active-label {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
  //   padding: 0 16px;
  .bottom-btn {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    .ant-btn {
      height: 32px;
      font-size: 12px;
    }
  }
}
</style>
